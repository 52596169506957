import * as React from "react";
import { FunctionComponent, useState } from "react";
import { Button, Heading, Input, InputHelper, Page } from "../components";
import { useStaticQuery, graphql } from "gatsby";
import { togglePopup } from "../utilities/index";

const Recaptcha = require("react-recaptcha");

const SurepayCancellationForm: FunctionComponent = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    {
      accountNumberSample: file(name: { eq: "hmc-account-number-sample" }) {
        publicURL
      }
    }
  `);

  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const recaptchaVerifyCallback = response => {
    setCaptchaVerified(true);
    setCaptchaToken(response);
  };

  const recaptchaExpiredCallback = _response => {
    setCaptchaVerified(false);
    setCaptchaToken("");
  };

  return (
    <Page title="SurePay Cancellation" layout="default">
      <form
        method="POST"
        action={`${
          process.env.GATSBY_HMCMGT_API_GATEWAY_URL
        }/surepay-cancellation`}
      >
        <Heading style={{ textAlign: "center" }} level={1}>
          SurePay Cancellation Form
        </Heading>
        <p style={{ textAlign: "center" }}>
          {`The Online Cancellation Form must be received by Hawaiiana on or before the 15th of a month to terminate SurePay service for the following month.`}
        </p>

        <hr />
        <fieldset>
          <div className="input--split">
            <label htmlFor="effective_cancellation_date">
              Effective Cancellation Date
            </label>
            <Input
              id="effective_cancellation_date"
              name="effective_cancellation_date"
              type="date"
              required
            />
          </div>
        </fieldset>
        <fieldset>
          <legend>Owner Details</legend>

          <div>
            <div className="input--split">
              <label htmlFor="first_name">First Name</label>
              <Input id="first_name" name="first_name" required />
            </div>
            <div className="input--split">
              <label htmlFor="last_name">Last Name</label>
              <Input id="last_name" name="last_name" required />
            </div>
          </div>

          <div>
            <label htmlFor="association">Property Name</label>
            <Input id="association" name="association_name" required />
          </div>

          <div>
            <label htmlFor="unit_number">Unit Number</label>
            <Input id="unit_number" name="unit_number" required />
          </div>

          <div>
            <label htmlFor="account_number">Hawaiiana Account Number</label>
            <Input
              id="account_number"
              name="hawaiiana_account_number"
              required
              style={{ margin: "0" }}
            />
            <InputHelper
              id="account-number-example"
              label="Need help locating your account number?"
              style={{ margin: "0 0 1rem 0" }}
              cb={togglePopup}
            >
              <img src={data.accountNumberSample.publicURL} />
            </InputHelper>
          </div>

          <div>
            <label htmlFor="phone_number">Phone Number</label>
            <Input id="phone_number" name="phone_number" type="tel" required />
          </div>

          <div>
            <label htmlFor="email_address">Email Address</label>
            <Input id="email_address" name="email_address" required />
          </div>
        </fieldset>

        <hr />
        <div>
          <p>
            {`By completing and submitting this Online Cancellation Form, I authorize Hawaiiana Management 
          Company, Ltd. (Hawaiiana) to terminate my Surepay service. The Online Cancellation Form must be 
          received by Hawaiiana on or before the 15th of a month to terminate service for the following month; 
          otherwise termination will take place after the following month's payment.`}
          </p>
          <Button
            href="/electronic-communications-notice"
            style={{ marginBottom: "1rem" }}
            target="_blank"
            rel="noreferrer"
          >
            View the Consent and Notice Regarding Electronic Communication
          </Button>
        </div>
        <Input
          type="checkbox"
          id="agrees_to_electronic_communication"
          name="agrees_to_electronic_communication"
          required
        />
        <label htmlFor="agrees_to_electronic_communication">
          {`I agree to the terms and conditions in the "Consent and Notice Regarding Electronic Communication"`}
        </label>
        <br />

        <Input
          type="checkbox"
          id="agrees_to_terms_and_conditions"
          name="agrees_to_terms_and_conditions"
          required
        />
        <label htmlFor="agrees_to_terms_and_conditions">
          {`I agree to the Terms and Conditions`}
        </label>

        <p>
          {`Type your name in the *Signature Block*. By typing in your name, you are signing this form electronically 
        and you agree your electronic signature is the legal equivalent of your manual signature on this form and 
        that you are authorizing the changes you have made on this form.`}
        </p>

        <div>
          <label htmlFor="electronic_signature">Signature</label>
          <Input
            id="electronic_signature"
            name="electronic_signature"
            required
          />
        </div>

        <div id="recaptcha-container">
          {typeof window !== "undefined" && (
            <Recaptcha
              elementId="recaptcha-container"
              render="explicit"
              sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
              verifyCallback={recaptchaVerifyCallback}
              onloadCallback={console.debug}
              expiredCallback={recaptchaExpiredCallback}
            />
          )}
        </div>
        <Input
          id="captcha_token"
          name="captcha_token"
          type="hidden"
          value={captchaToken}
          required
        />

        {isCaptchaVerified && (
          <p style={{ textAlign: "center" }}>
            <Button type="submit">Submit</Button>
          </p>
        )}
      </form>
    </Page>
  );
};

export default SurepayCancellationForm;
